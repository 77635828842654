import routerOptions0 from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.50_drizzle-orm@0.29.5_eslint@8.57.1_rollup@4.21.3_sass@1.78.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/builds/ldseating/nido/app/router.options.js";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}