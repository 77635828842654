type ObjectValues<T> = T[keyof T]

export const NidoPlacementSize = {
  UNIT: 0.6,
  HALF: 0.3,
} as const

export type NidoPlacementSize = ObjectValues<typeof NidoPlacementSize>

export const NidoSize = {
  DEFAULT_HEIGHT: 0.45,
  DEFAULT_DEPTH: 0.61,
  BACKREST: 0.13,
  SINGLE_MODULE_BACKREST_WIDTH: 0.27,
  LOW_BACKREST_HEIGHT: 0.32,
  HIGH_BACKREST_HEIGHT: 0.52,
  // TODO
  TABLE_HEIGHT: 0.32,
  // width to subtract for each neighbour - neighbours are squeezed closer to each other
  NEIGHBOUR_SQUEEZE: 0.005,
} as const

export type NidoSize = ObjectValues<typeof NidoSize>

export const NidoSingleModuleSize = {
  WIDTH: 0.64,
  HEIGHT: NidoSize.DEFAULT_HEIGHT,
  DEPTH: 0.59,
} as const

export type NidoSingleModuleSize = ObjectValues<typeof NidoSingleModuleSize>

const NidoSingleSize = {
  WIDTH: 0.61,
  HEIGHT: NidoSize.DEFAULT_HEIGHT,
  DEPTH: NidoSize.DEFAULT_DEPTH,
}

export type NidoSingleSize = ObjectValues<typeof NidoSingleSize>

const NidoCornerSize = NidoSingleSize

export type NidoCornerSize = ObjectValues<typeof NidoSingleSize>

export { NidoSingleSize, NidoCornerSize }

export const NidoDoubleSize = {
  WIDTH: 1.21,
  HEIGHT: NidoSize.DEFAULT_HEIGHT,
  DEPTH: NidoSize.DEFAULT_DEPTH,
} as const

export type NidoDoubleSize = ObjectValues<typeof NidoDoubleSize>

export const NidoTripleSize = {
  WIDTH: 1.81,
  HEIGHT: NidoSize.DEFAULT_HEIGHT,
  DEPTH: NidoSize.DEFAULT_DEPTH,
} as const

export type NidoTripleSize = ObjectValues<typeof NidoTripleSize>

export const NidoSeatsCount = {
  TRIPLE: 3,
  DOUBLE: 2,
  SINGLE: 1,
  CORNER: 1,
} as const

export type NidoSeatsCount = ObjectValues<typeof NidoSeatsCount>

export const NidoPosition = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
  BOTTOM: 'bottom',
} as const

export type NidoPosition = ObjectValues<typeof NidoPosition>

export const NidoPartName = {
  TABLE: 'table',
  SEAT: 'seat',
  CAGE: 'cage',
} as const

export type NidoPartName = ObjectValues<typeof NidoPartName>

export const NidoType = {
  SINGLE: 'single',
  DOUBLE: 'double',
  TRIPLE: 'triple',
  CORNER: 'corner',
} as const

export type NidoType = ObjectValues<typeof NidoType>

export const NidoSocketType = {
  SOCKET_CZ: 'socket-cz',
  SOCKET_DE: 'socket-de',
} as const

export type NidoSocketType = ObjectValues<typeof NidoSocketType>

export const NidoGliderType = {
  NONE: 'glider-none',
  FELT: 'glider-felt',
} as const

export type NidoGliderType = ObjectValues<typeof NidoGliderType>

export const NidoCode = {
  PILLOW: 'NI-BO',
  PILLOW_CORNER: 'NI-BO-CO',
  SEAT: 'NI-S',
  CAGE_SINGLE: 'CAGE_SINGLE',
  CAGE_DOUBLE: 'CAGE_DOUBLE',
  CAGE_TRIPLE: 'CAGE_TRIPLE',
  CAGE_CORNER: 'CAGE_CORNER',
  TABLE: 'NI-TP',
  HIGH_BACKREST: 'NI-B-H',
  LOW_BACKREST: 'NI-B-L',
  HIGH_BACKREST_CORNER: 'NI-CI-H',
  LOW_BACKREST_CORNER: 'NI-CI-L',
  HIGH_BACKREST_INNER_CORNER: 'NI-CO-H',
  LOW_BACKREST_INNER_CORNER: 'NI-CO-L',
  SINGLE: 'NI-BE1-N1',
  DOUBLE: 'NI-BE2-N1',
  TRIPLE: 'NI-BE3-N1',
  CORNER: 'NI-CO-N1',
  SINGLE_PREDEFINED_LOW: 'NI-K1-L-N1',
  SINGLE_PREDEFINED_HIGH: 'NI-K1-H-N1',
  DOUBLE_PREDEFINED_LOW: 'NI-K2-L-N1',
  DOUBLE_PREDEFINED_HIGH: 'NI-K2-H-N1',
  TRIPLE_PREDEFINED_LOW: 'NI-K3-L-N1',
  TRIPLE_PREDEFINED_HIGH: 'NI-K3-H-N1',
  SINGLE_WOOD: 'NI-BE1-D',
  DOUBLE_WOOD: 'NI-BE2-D',
  TRIPLE_WOOD: 'NI-BE3-D',
  CORNER_WOOD: 'NI-CO-D',
  SINGLE_PREDEFINED_LOW_WOOD: 'NI-K1-L-D',
  SINGLE_PREDEFINED_HIGH_WOOD: 'NI-K1-H-D',
  DOUBLE_PREDEFINED_LOW_WOOD: 'NI-K2-L-D',
  DOUBLE_PREDEFINED_HIGH_WOOD: 'NI-K2-H-D',
  TRIPLE_PREDEFINED_LOW_WOOD: 'NI-K3-L-D',
  TRIPLE_PREDEFINED_HIGH_WOOD: 'NI-K3-H-D',
} as const

export type NidoCode = ObjectValues<typeof NidoCode>

export const NidoSocketCode = {
  [NidoSocketType.SOCKET_CZ]: 'E-CZ',
  [NidoSocketType.SOCKET_DE]: 'E-DE',
} as const

export type NidoSocketCode = ObjectValues<typeof NidoSocketCode>

export const NidoId = {
  SINGLE: 192304,
  DOUBLE: 192306,
  TRIPLE: 192308,
  CORNER: 192320,
  LOW_BACKREST: 192310,
  HIGH_BACKREST: 192312,
  LOW_BACKREST_CORNER: 192314,
  HIGH_BACKREST_CORNER: 192316,
  LOW_BACKREST_INNER_CORNER: 192322,
  HIGH_BACKREST_INNER_CORNER: 192324,
  PILLOW: 192318,
  PILLOW_CORNER: 235546,
  NONE: 0,
  TABLE: 234227,
  SOCKET: 234232,
  CONNECT: 233942,
  SINGLE_PREDEFINED_LOW: 192298,
  SINGLE_PREDEFINED_HIGH: 192300,
  DOUBLE_PREDEFINED_LOW: 192302,
  DOUBLE_PREDEFINED_HIGH: 233974,
  TRIPLE_PREDEFINED_LOW: 234007,
  TRIPLE_PREDEFINED_HIGH: 234024,
  SINGLE_WOOD: 257554,
  DOUBLE_WOOD: 257520,
  TRIPLE_WOOD: 257537,
  CORNER_WOOD: 257571,
  SINGLE_PREDEFINED_LOW_WOOD: 257402,
  SINGLE_PREDEFINED_HIGH_WOOD: 257419,
  DOUBLE_PREDEFINED_LOW_WOOD: 257436,
  DOUBLE_PREDEFINED_HIGH_WOOD: 257453,
  TRIPLE_PREDEFINED_LOW_WOOD: 257469,
  TRIPLE_PREDEFINED_HIGH_WOOD: 257486,
} as const

export type NidoId = ObjectValues<typeof NidoId>

export const NidoSocketId = {
  [NidoSocketType.SOCKET_CZ]: 234232,
  [NidoSocketType.SOCKET_DE]: 234237,
} as const

export type NidoSocketId = ObjectValues<typeof NidoSocketId>

export const NidoGliderId = {
  [NidoGliderType.FELT]: 345,
} as const

export type NidoGliderId = ObjectValues<typeof NidoGliderId>

export const NidoSerieId = 191536

export const NidoCategory = {
  ONE: 0,
  TWO: 1,
  THREE: 2,
  FOUR: 3,
} as const

export type NidoCategory = ObjectValues<typeof NidoCategory>

export const NidoCageType = {
  WOOD: 'wood',
  METAL: 'metal',
} as const

export type NidoCageType = ObjectValues<typeof NidoCageType>

export const AxisPosition = {
  LEFT: 'left',
  RIGHT: 'right',
  X: 'x',
  Z: 'z',
} as const

export type AxisPosition = ObjectValues<typeof AxisPosition>

export const PointType = {
  ADD: 'add',
  CONFIG: 'config',
} as const

export type PointType = ObjectValues<typeof PointType>

export const Axis = {
  X: 'x',
  Z: 'z',
} as const

export type Axis = ObjectValues<typeof Axis>
