import revive_payload_client_yYsc9gEcKv from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.50_drizzle-orm@0.29.5_eslint@8.57.1_rollup@4.21.3_sass@1.78.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_JCpSmwouQR from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.50_drizzle-orm@0.29.5_eslint@8.57.1_rollup@4.21.3_sass@1.78.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_XzZYRDfVYh from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.50_drizzle-orm@0.29.5_eslint@8.57.1_rollup@4.21.3_sass@1.78.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_5QEFgtcrzm from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.50_drizzle-orm@0.29.5_eslint@8.57.1_rollup@4.21.3_sass@1.78.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_geoztbs5WS from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.50_drizzle-orm@0.29.5_eslint@8.57.1_rollup@4.21.3_sass@1.78.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_dIhZnM0JAG from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.50_drizzle-orm@0.29.5_eslint@8.57.1_rollup@4.21.3_sass@1.78.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_mrN1F7Qxw0 from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.50_drizzle-orm@0.29.5_eslint@8.57.1_rollup@4.21.3_sass@1.78.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_WDs8cMsseg from "/builds/ldseating/nido/node_modules/.pnpm/@pinia+nuxt@0.5.4_rollup@4.21.3_typescript@5.6.2_vue@3.5.6/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/ldseating/nido/.nuxt/components.plugin.mjs";
import prefetch_client_GDs8YMqdW5 from "/builds/ldseating/nido/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.50_drizzle-orm@0.29.5_eslint@8.57.1_rollup@4.21.3_sass@1.78.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_EIRYyIGgzF from "/builds/ldseating/nido/node_modules/.pnpm/@nuxt+ui@2.18.4_qrcode@1.5.4_rollup@4.21.3_vite@5.4.6_vue@3.5.6/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_fOuI9qFpwx from "/builds/ldseating/nido/node_modules/.pnpm/@nuxt+ui@2.18.4_qrcode@1.5.4_rollup@4.21.3_vite@5.4.6_vue@3.5.6/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_Sk6s8Gv3Ym from "/builds/ldseating/nido/node_modules/.pnpm/@nuxt+ui@2.18.4_qrcode@1.5.4_rollup@4.21.3_vite@5.4.6_vue@3.5.6/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_bYFa95F1nV from "/builds/ldseating/nido/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_rollup@4.21.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_znbKlcbbRZ from "/builds/ldseating/nido/node_modules/.pnpm/@nuxt+icon@1.5.1_rollup@4.21.3_vite@5.4.6_vue@3.5.6/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import switch_locale_path_ssr_8rAQ1wjiwV from "/builds/ldseating/nido/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_rollup@4.21.3_vue@3.5.6/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_ZDWvn13DfA from "/builds/ldseating/nido/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_rollup@4.21.3_vue@3.5.6/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import sentry_client_shVUlIjFLk from "/builds/ldseating/nido/plugins/sentry.client.ts";
export default [
  revive_payload_client_yYsc9gEcKv,
  unhead_JCpSmwouQR,
  router_XzZYRDfVYh,
  payload_client_5QEFgtcrzm,
  navigation_repaint_client_geoztbs5WS,
  check_outdated_build_client_dIhZnM0JAG,
  chunk_reload_client_mrN1F7Qxw0,
  plugin_vue3_WDs8cMsseg,
  components_plugin_KR1HBZs4kY,
  prefetch_client_GDs8YMqdW5,
  slideovers_EIRYyIGgzF,
  modals_fOuI9qFpwx,
  colors_Sk6s8Gv3Ym,
  plugin_client_bYFa95F1nV,
  plugin_znbKlcbbRZ,
  switch_locale_path_ssr_8rAQ1wjiwV,
  i18n_ZDWvn13DfA,
  sentry_client_shVUlIjFLk
]